import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { cantidadValidation, destinoValidation, fechaValidation, origenValidation, horarioValidation } from '../../../utils/inputValidation'
import { StyledForm } from '../Forms.styles'
import { Input } from '../index'
import PropTypes from 'prop-types'
import { Select } from '../components/Select'
import pasajesService from '../../../services/pasajesService'
import googleService from '../../../services/googleService'
import { useDispatch } from 'react-redux'
import { LoadingSpinner } from '../../../components'
import { getOrigenes, getDestinos, getHoras } from '../../../services/ubicacionesService'
import 'react-datepicker/dist/react-datepicker.css'

let origenesCompletos = []
let destinosCompletos = []
// const fecha = []
// const horas = []

export const FormNuevoViaje = ({ success, handleSuccess, handleNext, viaje }) => {
  const methods = useForm({ shouldUnregister: false })
  const [datosValidados, setDatosValidados] = useState(false)
  const [pasajeExistente, setPasajeExistente] = useState(false)
  const delay = ms => new Promise((resolve) => setTimeout(resolve, ms))
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [segundoSelectDisponible, setSegundoSelectDisponible] = useState(true)
  const [tercerSelectDisponible, setTercerSelectDisponible] = useState(true)
  const [cuartoSelectDisponible, setCuartoSelectDisponible] = useState(true)

  const [origenSelected, setOrigenSelected] = useState('')

  const [destinoSelected, setDestinoSelected] = useState('')

  const [fecha, setFecha] = useState('')

  const [loadingHoras, setLoadingHoras] = useState(false)

  const updateDestinosFromOriginId = async (origenId) => {
    setLoadingHoras(true)
    await getDestinos(origenId).then(destinos => {
      destinoValidation.arrayValues = destinos.map(destino => destino.nombre)
      destinosCompletos = destinos
      setDestinoSelected(destinos[0])
      setLoadingHoras(false)
    })
  }

  const handleOrigenChange = async (event) => {
    setSegundoSelectDisponible(true)
    setTercerSelectDisponible(true)
    setCuartoSelectDisponible(true)
    destinoValidation.arrayValues = []
    horarioValidation.arrayValues = []
    setDestinoSelected('')
    setFecha('')

    const estacion = event.target.value
    const origen = origenesCompletos.find(origen => origen.nombre === estacion)
    console.log(origen)
    setOrigenSelected(origen)
    await updateDestinosFromOriginId(origen.id)

    setSegundoSelectDisponible(false)
    setTercerSelectDisponible(false)
  }

  const handleDestinoChange = (event) => {
    horarioValidation.arrayValues = []
    setTercerSelectDisponible(true)
    setCuartoSelectDisponible(true)

    const estacion = event.target.value
    const destino = destinosCompletos.find(destino => destino.nombre === estacion)
    // no actualiza las horas
    setDestinoSelected(destino)
    setTercerSelectDisponible(false)
    setCuartoSelectDisponible(false)
  }

  const handleDateChange = (event) => {
    const date = event.target.value
    const dateParts = date.split('-')
    const formattedDate = `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`
    setFecha(formattedDate)
    horarioValidation.arrayValues = []
  }

  // Primer renderizado del componente
  useEffect(() => {
    console.log('volviendo a montar componente')
    origenValidation.arrayValues = []
    destinoValidation.arrayValues = []
    horarioValidation.arrayValues = []
    setOrigenSelected('')
    setDestinoSelected('')
    setFecha('')
    getOrigenes().then(origenes => {
      origenValidation.arrayValues = origenes.map(origen => origen.nombre)
      origenesCompletos = origenes
      setOrigenSelected(origenesCompletos[0])
      updateDestinosFromOriginId(origenesCompletos[0].id).then(
        (data) => {
          console.log('obtuvimos los valores')
          setSegundoSelectDisponible(false)
          setTercerSelectDisponible(false)
        }
      )
    })
  }, [])

  const onSubmit = methods.handleSubmit(async data => {
    viaje.changeValue(data)
    setLoading(true)
    try {
      const response = await pasajesService.createTicket(data)
      if (response) {
        setLoading(false)
        setPasajeExistente(false)
        setDatosValidados(true)
        googleService.checkUser(dispatch)
        await delay(2000)
        handleSuccess(true)
      } else {
        setLoading(false)
        setPasajeExistente(true)
      }
    } catch (e) {
      setLoading(false)
      setPasajeExistente(true)
    }
  })

  // Para que cada vez que se desmonte la componente se recuperen con los valores anteriores.
  useEffect(() => {
    // methods.setValue('origen', viaje.getValue().origen)
    // methods.setValue('destino', viaje.getValue().destino)
    // methods.setValue('pasajeros', viaje.getValue().pasajeros)
    // methods.setValue('fecha', viaje.getValue().fecha)
    handleSuccess(false)
  }, [])

  useEffect(() => {
    console.log('entro form')
    console.log(origenSelected, destinoSelected, fecha)
    if (origenSelected !== '' && destinoSelected !== '' && fecha !== '') {
      setLoadingHoras(true)
      setCuartoSelectDisponible(true)
      getHoras(fecha, origenSelected.id, destinoSelected.id).then(horas => {
        // Filtro de horas
        // const fechaMarzo = fecha.split('/')

        // // Nuevos horarios para Mar del Plata
        // const horasBA = ['07:22', '12:41']
        // const horasBAFriday = ['07:22', '12:41', '17:10']
        // const horasBASaturday = ['07:13', '12:29']
        // const horasBASunday = ['07:00', '12:18']

        // // Nuevos horarios para Buenos Aires
        // const horasMDQ = ['01:22', '14:50']
        // const horasMDQSaturday = ['01:20', '14:50']
        // const horasMDQSunday = ['01:25', '14:28']
        // console.log(fechaMarzo[1])
        // if (((fechaMarzo[1] === '12') || (fechaMarzo[1] === '01') || (fechaMarzo[1] === '02')) && fechaMarzo[0] >= 1) {
        //   let diaSemana
        //   if (fechaMarzo[1] === '12') {
        //     diaSemana = new Date('2024-12-' + fechaMarzo[0]).getDay()
        //   } else if (fechaMarzo[1] === '01') {
        //     diaSemana = new Date('2025-01-' + fechaMarzo[0]).getDay()
        //   } else if (fechaMarzo[1] === '02') {
        //     diaSemana = new Date('2025-02-' + fechaMarzo[0]).getDay()
        //   }
        //   console.log(diaSemana)
        //   switch (origenSelected.nombre) {
        //     case 'Buenos Aires':

        //       switch (diaSemana) {
        //         case 4:
        //           horas = horasBAFriday
        //           break
        //         case 5: // Sábado
        //           horas = horasBASaturday
        //           break
        //         case 6: // Domingo
        //           horas = horasBASunday
        //           break
        //         default:
        //           horas = horasBA
        //           break
        //       }
        //       break

        //     case 'Mar del Plata':
        //       switch (diaSemana) {
        //         case 5: // Sábado
        //           horas = horasMDQSaturday
        //           break
        //         case 6: // Domingo
        //           horas = horasMDQSunday
        //           break
        //         default:
        //           horas = horasMDQ // Si no es lunes, sábado ni domingo, se define como horario a definir
        //           break
        //       }
        //       break

        //     default:
        //       horas.push('Horario a definir')
        //       break
        //   }
        // }
        horarioValidation.arrayValues = horas

        // setHorarios(horas)
        setLoadingHoras(false)
        setCuartoSelectDisponible(false)
      })
    }
  }, [origenSelected, destinoSelected, fecha])

  // const filtrarHoras = (horas, horarioInvalidado) => {
  //   const horarioFiltrados = horas.filter(hora => !horarioInvalidado.includes(hora))
  //   return horarioFiltrados
  // }

  return (
    <FormProvider {...methods} >
      <StyledForm onSubmit={ e => e.preventDefault()}
        noValidate
        className='container'>
          {/* <InputError message={'No hay servicio de trenes los dias : 7, 8, 9, 21, 22 y 23 de Noviembre'}></InputError> */}
          <Select handleChange={handleOrigenChange} {...origenValidation} />
          <Select handleChange={handleDestinoChange}{...destinoValidation} disabled = {segundoSelectDisponible}/>
          <Select {...cantidadValidation}/>
          <Input className = "fecha" handleChange={handleDateChange}{...fechaValidation} disabled = {tercerSelectDisponible}/>
          <Select {...horarioValidation} disabled = {cuartoSelectDisponible}/>
          <div className='boleteria'>
            <button
              onClick={onSubmit}
              className="flex items-center gap-1 p-5 font-semibold text-white bg-blue-600 rounded-md hover:bg-blue-800"
            >
            Buscar pasaje
            </button>
          </div>
      </StyledForm>
      {
        <LoadingSpinner loading = {loading}></LoadingSpinner>
      }
      {<LoadingSpinner loading = {loadingHoras}></LoadingSpinner>}
      {datosValidados && (
            <p className="flex items-center gap-1 mb-5 font-semibold text-green-500">
              Pasaje verificado!
            </p>
      )}
      {pasajeExistente && (
            <p className="flex items-center gap-1 mb-5 font-semibold text-red-500">
              ¡Ya tiene un pasaje reservado en esa fecha y horario!.
            </p>
      )}
    </FormProvider>
  )
}

FormNuevoViaje.propTypes = {
  success: PropTypes.boolean,
  handleSuccess: PropTypes.func,
  handleNext: PropTypes.func,
  viaje: PropTypes.object
}
